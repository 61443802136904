import React, { Fragment } from 'react';
import Moment from "moment";
import Helper from "../../../services/helperService";
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";

const DetailsAllOther = ({
  category,
  sub,
  tableRawData,
  apiData,
  convertedData,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  noHeader,
  moveToCategory
}) => {

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

  const retainedTypes = Object.keys(retained).filter(key => retained[key].isRetained);

  const customFieldsData = apiData?.violationDetails?.customFieldData ? apiData?.violationDetails?.customFieldData[0]?.fields || [] : [];

  return (
    // All Other Violations Show General Design
    Object.entries(convertedData).map(([paragraphName, values], key) => {

      return <Fragment key={key + 100}>
        <div className="card user-card ecb-head"
          style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
          <RetainedBadges retained={retained} />

          <div className="card-body">
            {noHeader ?
              <div className="ecb-sub-head">
                <h5>{paragraphName}</h5>
              </div>
              : <div className="col-12 row">
                <h5 className="text-info mb-3 pointer" onClick={moveToCategory}>{category}/{sub}</h5>
              </div>

            }
            <div className="ecb-row row">
              {values.map((value, key) => {
                let displayValue = tableRawData[value.apiField];

                // Converting Date
                if (displayValue && ((value.apiField.search(/date/i) !== -1) || (value.displayName.search(/date/i) !== -1)) && typeof displayValue !== 'undefined') {
                  if (Moment(displayValue).isValid()) {
                    displayValue = Moment(displayValue).format('LL');
                  } else {
                    displayValue = '--';
                  }
                }

                // Check if we need to add $ sign
                if (displayValue && value.dollarAmount) {
                  displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                }

                /*if (typeof displayValue === 'string') {
                  // eslint-disable-next-line
                  if (displayValue.search(/http/i) !== -1)
                    displayValue =
                      <a className="badge badge-info" rel="noopener noreferrer" href={displayValue} target="_blank">Make
                        a Payment <i className="fa fa-external-link" /></a>;
                }*/

                // ECB Ticket#, let's add violation URL
                if ((value.apiField.search(/Ticket_number/i) !== -1)) {
                  displayValue = <>
                    <div>{displayValue} </div>
                    <a className="badge badge-info" rel="noopener noreferrer"
                      href={'http://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=' + displayValue}
                      target="_blank">
                      View Image of Summons/Notice <i className="fa fa-external-link" />
                    </a>
                    {(tableRawData.issuing_agency === "DEPT. OF BUILDINGS") &&
                      <a className="badge badge-info" rel="noopener noreferrer"
                        href={`http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=1&allbin=${this.state.addressData.bin}&ecbin=${displayValue}`}
                        target="_blank">
                        DOB <i className="fa fa-external-link" />
                      </a>
                    }

                    <a className="badge badge-info" rel="noopener noreferrer"
                      href={`https://account.bcompliant.com/redirect/ecb.html?violation_id=${displayValue}`}
                      target="_blank">
                      ECB <i className="fa fa-external-link" />
                    </a>
                  </>;
                }

                return (
                  <div className="col-6 col-md-4 mb-3" key={key}>
                    <h6>{value.displayName}:</h6>
                    <p>{displayValue || 'N/A'}</p>
                  </div>
                )
              })

              }
              {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
                <div className="ecb-notes">
                  <h5>
                  BCompliant Violation Notes
                  </h5>
                  <div className="desc">
                    <p>
                      {apiData.violationDetails.additionalData.bcompliantNotes}
                    </p>
                  </div>
                  {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                    Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
                  </small>}
                </div>
              }
            </div>

            {apiData.violationDetails?.hpdMoreConditions && apiData.violationDetails?.hpdMoreConditions.length > 0 && <>
              <h6 className="text-dark font-weight-bold mb-3">Also Reported As:</h6>
              <div className='table-responsive'>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th className='p-1'>Conditions</th>
                      <th className='p-1'>Details</th>
                      <th className='p-1'>Location</th>
                      <th className='p-1'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData.violationDetails.hpdMoreConditions.map((condition, key) => (
                      <tr>
                        <td className='p-1'>{condition.complaint_condition}</td>
                        <td className='p-1'>{condition.condition_detail}</td>
                        <td className='p-1'>{condition.location}</td>
                        <td className='p-1'>{condition.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
            }
          </div>
          <CustomFields
            customFields={customFields}
            subUsers={subUsers}
            vendors={vendors}
            category={category}
            sub={sub}
            customFieldsData={customFieldsData}
            apiData={apiData}
            setRefreshHistory={setRefreshHistory}
          />
          <CofCProgress cofc={cofc} />
        </div>
      </Fragment>
    })
  )
    ;
};

export default DetailsAllOther;
